/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  createClient,
  ContentfulClientApi,
  ContentType,
  EntryCollection,
} from "contentful";

type IContentfulContext = {
  client: ContentfulClientApi;
  allEntries: EntryCollection<ContentType> | Record<string, any>;
};

export const contentClient = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? "",
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN ?? "",
});

export const ContentfulContext = createContext({
  client: contentClient,
  allEntries: {},
} as IContentfulContext);

// do not initialize the provider with a value
// if we do NOT want to use allEntries
// then we dont need to set it at the beginning
// if we want to use it, then we set the value
// export const ContentfulProvider = ContentfulContext.Provider;

export const useContentfulContext = () => useContext(ContentfulContext);

export const ContentfulProvider = ({
  children,
  locale,
  useAllEntries,
}: {
  children: ReactNode;
  locale?: string;
  useAllEntries?: boolean;
}) => {
  const [contextValue, setContextValue] = useState({
    client: contentClient,
    allEntries: {},
  });
  useEffect(() => {
    const fetchData = async () => {
      console.log("csr");

      const providerValue = { client: contentClient, allEntries: {} };
      if (useAllEntries) {
        providerValue.allEntries = await contentClient.getEntries({
          locale: locale,
        });
      }
      setContextValue(providerValue);
    };
    fetchData();
  }, []);
  return (
    <ContentfulContext.Provider value={contextValue}>
      {children}
    </ContentfulContext.Provider>
  );
};

/* eslint-disable max-len */
import { AppProps } from "next/app";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import AOS from "aos";
// Css
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../node_modules/react-perfect-scrollbar/dist/css/styles.css";
import "../../styles/assets/fonts/flaticon/flaticon.css";
import "../../styles/assets/fonts/font-awesome/css/all.min.css";
import "../../styles/assets/css/style.css";
import "../../styles/index.css";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { ContentfulProvider } from "../context/ContentfulContext";
import { useRouter } from "next/router";

const App = ({ Component, pageProps }: AppProps) => {
  const useAllEntries = true;
  const { locale } = useRouter();
  useEffect(() => {
    AOS.init({ duration: 800 });
  }, []);
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#d44245" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <ContentfulProvider useAllEntries={useAllEntries} locale={locale}>
        <Component {...pageProps}></Component>
      </ContentfulProvider>
    </>
  );
};

export default appWithTranslation(App);
